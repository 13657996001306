







import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'LabelBadge' })
export class LabelBadge extends Vue {
  @Prop({ type: String, required: true })
  public readonly label!: string

  /**
   * Emits event to remove `label`
   */
  public remove (): void {
    this.$emit('remove')
  }
}
export default LabelBadge
