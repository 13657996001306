












































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { PickerCallback, RelatedType, UnresolvedImage } from '../../../../../contracts'
import {
  getNonExistentRelated,
  ResolvesRelated,
  ResolvesRelatedAsync
} from '../../../../../services'

import { FormFieldset } from '../../../../atoms'
import { ImageForm } from '../../../../molecules'

import { QuoteModuleAuthor } from '../../Quote.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<AuthorForm>({
  name: 'AuthorForm',
  components: { FormFieldset, ImageForm }
})
export class AuthorForm extends Vue {
  @PropSync('author', { type: Object, required: true })
  public _author!: QuoteModuleAuthor

  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  @Prop({ type: Object, required: true })
  protected readonly relatedService!: ResolvesRelated | ResolvesRelatedAsync

  public get image (): UnresolvedImage | null {
    if (!this._author || !this._author.image || typeof this._author.image.value === 'undefined') {
      return null
    }

    return { image: { ...this._author.image } }
  }

  public set image (image: UnresolvedImage | null) {
    if (!image || !image.image) {
      this._author.image = getNonExistentRelated(RelatedType.File)
    } else {
      this._author.image = image.image
    }

    this.$emit('update:author', { ...this._author })
  }

  public onAuthorChange (): void {
    this.$emit('update:author', { ...this._author })
  }
}

export default AuthorForm

