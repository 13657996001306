



















































import { Component, Watch } from 'vue-property-decorator'

import { MarkdownEditor } from '../../../molecules/MarkdownEditor'

import { FormFieldset, UiDataCircleSize } from '../../../atoms'
import { MapSelector } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { statsContentFactory } from '../Stats.factory'
import {
  StatsModule,
  StatsModuleCircleVersionContent,
  StatsModuleTextVersionContent,
  StatsModuleVersion
} from '../Stats.contracts'

/**
 * TODO: Document all methods and properties.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component({
  name: 'StatsModuleForm',
  components: { FormFieldset, MapSelector, MarkdownEditor }
})
export class StatsModuleForm extends AbstractModuleForm<StatsModule> {
  /**
   * @inheritDoc
   */
  public initialContent: (
    StatsModuleCircleVersionContent | StatsModuleTextVersionContent
  ) = statsContentFactory()

  /**
   * Map of available module version
   */
  public readonly statsModuleVersion = StatsModuleVersion

  /**
   * Map of possible positions of 'value'
   */
  public readonly positionOptions = {
    top: 'top',
    left: 'left'
  }

  /**
   * Map of UiDataCircle sizes
   */
  public readonly UiDataCircleSize = UiDataCircleSize

  /**
   * Remove unnecessary keys from `_content` when version is changed
   * @param newVersion
   */
  @Watch('_version')
  onVersionChanged (newVersion: StatsModuleVersion): void {
    switch (newVersion) {
      case StatsModuleVersion.Circle:
        this._content = {
          value: this._content.value,
          label: this._content.label,
          size: ''
        }
        break
      case StatsModuleVersion.Text:
        this._content = {
          value: this._content.value,
          label: this._content.label,
          source: '',
          valuePosition: ''
        }
        break
      default:
        break
    }
  }
}

export default StatsModuleForm
