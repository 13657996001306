






























import { Component } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { HorizontalAlignment, Related, RelatedType } from '../../../../contracts'

import { FormFieldset } from '../../../atoms'
import { IconSelect } from '../../../atoms/IconSelect'
import { MapSelector } from '../../../molecules'
import { MarkdownEditor } from '../../../molecules/MarkdownEditor'

import { AbstractModuleForm } from '../../_abstract'

import { quoteContentFactory } from '../Quote.factory'
import { QuoteModule, QuoteModuleAuthor, QuoteModuleContent } from '../Quote.contracts'

import { AuthorForm } from './partials'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
@Component<QuoteModuleForm>({
  name: 'QuoteModuleForm',
  components: { AuthorForm, FormFieldset, MapSelector, MarkdownEditor, IconSelect }
})
export class QuoteModuleForm extends AbstractModuleForm<QuoteModule> {
  public icons = DashmixIconName
  public initialContent: QuoteModuleContent = quoteContentFactory()

  public readonly HorizontalAlignment = HorizontalAlignment

  public get author (): QuoteModuleAuthor {
    if (!this._content.author) {
      return {
        image: {} as unknown as Related<RelatedType.File>,
        name: ''
      }
    }
    return this._content.author
  }

  public set author (author: QuoteModuleAuthor) {
    this._content = {
      ...this._content,
      author: author
    }
  }
}

export default QuoteModuleForm
