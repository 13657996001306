




















































































import { Component } from 'vue-property-decorator'
import { DashmixIconName, DashmixSelectItem } from '@movecloser/ui-core'

import { Heading } from '../../../../contracts'

import { FormAddBtn, FormFieldset } from '../../../atoms'
import { HeadingForm, ImageForm, LinkForm } from '../../../molecules'
import { MarkdownEditor } from '../../../molecules/MarkdownEditor'

import { AbstractModuleForm } from '../../_abstract'

import { CardBackgroundColor, CardModule, CardModuleContent } from '../Card.contracts'
import { cardContentFactory } from '../Card.factory'

import { LabelBadge } from './partials'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component({
  name: 'CardModuleForm',
  components: {
    FormAddBtn,
    FormFieldset,
    HeadingForm,
    ImageForm,
    LabelBadge,
    LinkForm,
    MarkdownEditor
  }
})
export class CardModuleForm extends AbstractModuleForm<CardModule> {
  //
  public initialContent: CardModuleContent = cardContentFactory()

  public icons = DashmixIconName

  public newLabel: string = ''

  public get backgroundColors (): DashmixSelectItem[] {
    return [
      ...Object.entries(CardBackgroundColor).map(([label, value]) => {
        return {
          label,
          value
        }
      })
    ]
  }

  /**
   * Adds new label to `_content.labels`
   */
  public addNewLabel (): void {
    if (typeof this._content.labels === 'undefined' && !this._content.labels) {
      return
    }
    this._content.labels.push(this.newLabel)
    this.newLabel = ''
  }

  /**
   * Sets renewed heading
   */
  public onHeadingUpdate (heading: Heading): void {
    this._content = {
      ...this._content,
      heading: heading
    }
  }

  /**
   * Finds and removes label from `_content.labels`
   * @param label
   */
  public removeLabel (label: string): void {
    if (typeof this._content.labels === 'undefined' && !this._content.labels) {
      return
    }
    const copyArr = [...this._content.labels]

    const itemToRemove = copyArr.indexOf(label)
    copyArr.splice(itemToRemove, 1)

    this._content.labels = copyArr
  }
}

export default CardModuleForm
