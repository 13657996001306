






























import { Component } from 'vue-property-decorator'

import { HAlignmentSelector, Iterator } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { labelsContentFactory } from '../Labels.factory'
import { LabelsModule, LabelsModuleContent } from '../Labels.contracts'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
@Component({
  name: 'LabelsModuleForm',
  components: { Iterator, HAlignmentSelector }
})
export class LabelsModuleForm extends AbstractModuleForm<LabelsModule> {
  public initialContent: LabelsModuleContent = labelsContentFactory()

  public addNewLabel (): string {
    return ''
  }

  public onLabelChange (value: string, index: number): void {
    const labelsCopy = [...this._content.labels]
    labelsCopy[index] = value

    this._content.labels = labelsCopy
  }
}

export default LabelsModuleForm
